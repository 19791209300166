/* You can add global styles to this file, and also import other style files */

@import 'assets/style.css';
@import 'assets/font-awesome/css/font-awesome.min.css';
@import 'assets/perfect-scrollbar/dist/css/perfect-scrollbar.min.css';
@import 'assets/flag-icon-css/css/flag-icon.min.css';
.menu-title:hover{
    color: #ffa6a8;
}
.menu-title:active{
    color: #ffa6a8;
}